import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi There, I am <span className="purple">De-zeus Awah </span>
            from <span className="purple"> Dallas, Texas.</span>
            <br />
            I am currently employed as an HTMLer at Schoolsplp and a Freelance contractor.
            <br />
            I am currently doing a MSc in Computer Information Systems at Rivier University, Nashua - New Hampshire.
            <br />
            I have completed my BSc in Computer Information Systems(Honors) at the University of Namibia.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Video Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Enjoying Chess
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Through the simple act of perseverance, every challenge becomes a stepping stone to success."{" "}
          </p>
          <footer className="blockquote-footer">De-zeus</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
